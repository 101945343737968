import React, { useState, useEffect } from 'react';
import Beehive from "../assets/portfolio/Beehive.jpg";
import Pygame from "../assets/portfolio/pygame.png";
import Sorting from '../assets/portfolio/Sorting_Algorithms.png'
import Pollution from '../assets/portfolio/Pollution.png'
import Finance from '../assets/portfolio/Finance.png'
import Superhero from '../assets/portfolio/Superhero.png'

const Portfolio = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const portfolios = [
    {
      id: 1,
      src: Pollution,
      title: "Data Standardizer & Visualizer",
      description: "Built a tool to standardize various carbon units into tons/CO2, and then visualize the data using Streamlit. The accuarcy and visuals of our tool won us first place.",
      Language: ["Python", "Streamlit"],
      Demo: "https://wec2023.streamlit.app/",
      Code: "https://github.com/amurji22/WEC_2023",
    },
    {
      id: 2,
      src: Superhero,
      title: "Superhero Database Explorer",
      description: "Developed a web app that allows users to explore, save, and share their favorite superheroes. Used LowDB for data management and Firebase for user authentication.",
      Language: ["JavaScript", "React", "AWS", "FireBase"],
      Demo: "http://ec2-3-15-211-122.us-east-2.compute.amazonaws.com:3000/",
      Code: "https://github.com/amurji22/se3316-amurji22-lab4",
    },
    {
      id: 3,
      src: Finance,
      title: "Finance Database Manager",
      description: "Developaped an application to manage and categorize assets and liabilities. SQL was used for data handling and JavaFX for visualizing and interacting with financial data.",
      Language: ["Java", "Java FX", "SQL"],
      Code: "https://github.com/amurji22/iFinance-Database-Manager",
    },
    {
      id: 4,
      src: Sorting,
      title: "Sorting Algorithms Visualizer",
      description: "Created a JavaFX application for visualizing sorting algorithms, allowing users to easily understand and compare their effectiveness.",
      Language: ["Java", "Java FX"],
      Demo: "https://youtu.be/0uu7R38yq-Y",
      Code: "https://github.com/amurji22/Java-Sorting-Algorithm",
    },
    {
      id: 5,
      src: Pygame,
      title: "Sci-Fi Roleplaying Game",
      description: `Crafted an interactive "Choose Your Own Adventure" game with Pygame, featuring dynamic mechanics and an intuitive UI.`,
      Language: ["Python", "Pygame"],
      Demo: "https://youtu.be/_H3jnZM91d4",
      Code: "https://github.com/amurji22/Python-Sci-Fi-Roleplaying-Game",
    },
    {
      id: 6,
      src: Beehive,
      title: "Beehive Monitoring System",
      description: "Utilized Arduino sensors to proactively monitor a beehive's internal temperature, humidity, and food supply levels. Used during the winter this proactive system ensured colony health and prevented harm to the colonies that used to occur with physical checks.",
      Language: ["Arduino C"],
    },
  ];

  const handleButtonClick = (id, buttonType) => {
    const project = portfolios.find(p => p.id === id);
    if (!project) return;
  
    let link = '';
    if (buttonType === 'Demo') link = project.Demo;
    if (buttonType === 'Code') link = project.Code;
  
    if (link) window.open(link, '_blank');
  };

  return (
    <div className='bg-gradient-to-b from-black to-gray-800'>
    {windowWidth <= 550 && (
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        
      </>
    )}
    <div
      name="portfolio"
      className=" w-full text-white md:h-screen overflow-y-auto"
      style={{ minHeight: '1220px' }}
    >
      <div className="max-w-screen-lg mx-auto flex flex-col justify-center w-5/6 h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Portfolio
          </p>
          <p className="py-4">Check out some of my work</p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
        {portfolios.map(({ id, src, title, description, Language, Demo, Code }) => (
          <div key={id} className="shadow-md shadow-gray-600 rounded-lg">
            <img
              src={src}
              alt=""
              className="rounded-md duration-200 hover:scale-105"
            />
            <div className="p-4">
              <h3 className="text-xl font-semibold">{title}</h3>
              <p className="text-gray-500 text-center">{description}</p>
              <div className="flex flex-wrap mt-2 justify-center">
                {Language.map((language, index) => (
                  <div key={index} className="bg-gray-800 text-white text-xs font-bold px-2 py-1 rounded mr-2 mb-2">
                    {language}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-center">
                  {Demo && (
                <button
                  onClick={() => handleButtonClick(id, 'Demo')}
                  className="w-1/2 px-6 m-4 duration-200 hover:scale-105">
                  Demo
                </button>
              )}
              {Code && (
                <button
                  onClick={() => handleButtonClick(id, 'Code')}
                  className="w-1/2 px-6 m-4 duration-200 hover:scale-105">
                  Code
                </button>
              )}
            </div>
          </div>
        ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default Portfolio;
