import React, { useState, useEffect } from 'react';

import html from "../assets/html.png";
import css from "../assets/css.png";
import javascript from "../assets/javascript.png";
import reactImage from "../assets/react.png";
import Nodejs from "../assets/Node.js_logo.png";
import SQL from "../assets/Sql.png";
import Java from "../assets/Java.png";
import Python from "../assets/Python.png";
import tailwind from "../assets/tailwind.png";

function Experience() {
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const technologies = [
    {
      id: 1,
      src: html,
      title: 'HTML',
      style: 'shadow-orange-500'
    },
    {
        id: 2,
        src: reactImage,
        title: 'React',
        style: 'shadow-purple-600'
    },
    {
      id: 3,
      src: css,
      title: 'CSS',
      style: 'shadow-blue-500'
    },
    {
      id: 4,
      src: Python,
      title: 'Python',
      style: 'shadow-green-500'
    },
    {
      id: 5,
      src: tailwind,
      title: 'Tailwind CSS',
      style: 'shadow-sky-400'
    },
    {
      id: 6,
      src: SQL,
      title: 'SQL',
      style: 'shadow-red-500'
    },
    {
      id: 7,
      src: Java,
      title: 'Java',
      style: 'shadow-blue-500'
    },
    {
      id: 8,
      src: javascript,
      title: 'JavaScript',
      style: 'shadow-yellow-500'
    },
    {
      id: 9,
      src: Nodejs,
      title: 'Node.js',
      style: 'shadow-green-500'
    },
];

    return (
      <div
        name="experience"
        className="bg-gradient-to-b from-gray-800 to-black w-full h-screen overflow-y-auto"
        style={{ minHeight: '850px' }}
      >
           {windowWidth <= 550 && (
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        
      </>
    )}
        <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
          <div>
            <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
              Experience
            </p>
            <p className="py-6">These are the technologies I have worked with</p>
          </div>
  
          <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
            {technologies.map(({ id, src, title, style }) => (
              <div
                key={id}
                className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style} flex flex-col items-center`}
              >
                <div className="w-20 h-20 flex items-center justify-center overflow-hidden">
                  <img src={src} alt={title} className="w-full h-full object-contain" />
                </div>
                <p className="mt-4">{title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
    

export default Experience