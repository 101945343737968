import React, { useState, useEffect } from 'react';

function About() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div name='about' className='w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white'>
      {windowWidth <= 550 && (
        <>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      )}
      <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-gray-500'>About</p>
            </div>
            <p className='text-xl mt-10'>
            Currently, I have completed 3 years of the Software Engineering program at the University of Western Ontario and am now gaining hands-on experience through an internship. My education has been a mix of challenging coursework and practical projects. A standout experience was winning the Western Engineering Competition, where my team developed a Python-based carbon unit converter. This achievement highlights my skill in creating and executing complex solutions. Beyond academics, I focus on practical applications of technology, aiming to create meaningful and effective software solutions.
            </p>
            <br />
            <p className='text-xl'>
            I am the Co-President of Western Crypto Club, where I lead initiatives to educate and engage members in cryptocurrency and blockchain technology. As a Product Development Engineering Intern at Trudell Medical International, I contribute to innovative projects that combine software and hardware solutions. These experiences reflect my passion for technology, leadership, and delivering impactful results in the evolving tech landscape.
            </p>
      </div>
    </div>
  )
}

export default About